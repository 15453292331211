import { mapGetters } from "vuex";
import { getImageByPosition } from "~/utils/index.js";

export default {
  data() {
    return {
      productData: null,
      configurationInfo: null,
      sizesSlugs: ["ring-size", "necklace-size", "bracelet-size"],
    };
  },
  computed: {
    ...mapGetters({
      getMaterialsCategories: "general/getMaterialsCategories",
    }),
    productTitle() {
      return (
        this.configurationInfo?.mixedTranslation?.title ||
        this.dynamicProductTitle ||
        this.productData?.variant_plp_image?.mixedTranslation?.title ||
        this.productData?.default_config?.mixedTranslation?.title ||
        this.productData?.mixedTranslation?.title ||
        ""
      );
    },
    dynamicProductTitle() {
      return "";
    },
    productDescription() {
      return (
        this.configurationInfo?.mixedTranslation?.description ||
        this.productData?.variant_plp_image?.mixedTranslation?.description ||
        this.productData?.default_config?.mixedTranslation?.description ||
        this.productData?.mixedTranslation?.long_description ||
        ""
      );
    },
    productMaterials() {
      return this.productData?.material_categories_plp_hover_ids?.map((el) => {
        const materialCategory = this.getMaterialsCategories?.find(
          (categ) => categ.id === el
        );

        const materials = this.productData?.materials || [];
        return {
          ...materialCategory,
          materials: materials.reduce((acc, material) => {
            if (material.material_category_id === el) {
              acc.push({
                ...material,
              });
            }
            return acc;
          }, []),
        };
      });
    },
    productPrice() {
      return Number(
        this.configurationInfo?.cost ||
          this.productData?.variant_plp_image?.cost ||
          this.productData?.default_config?.cost ||
          this.productData?.min_cost ||
          this.productData?.base_cost ||
          0
      );
    },
    configurationPrice() {
      return Number(this.configurationInfo?.cost || 0);
    },
    configurationDiscountValue() {
      if (
        !this.productData?.sale ||
        !this.productData?.sale_value ||
        !this.configurationPrice
      ) {
        return 0;
      }

      return Number(
        (
          this.configurationPrice -
          this.configurationPrice * (+this.productData.sale_value / 100)
        ).toFixed(2)
      );
    },
    finalConfigurationPriceValue() {
      return this.configurationDiscountValue || this.configurationPrice || 0;
    },
    productSKU() {
      return (
        this.productData?.variant_plp_image?.sku ||
        this.productData?.default_config?.configuration_id ||
        this.productData?.default_sku
      );
    },
    discountValue() {
      if (
        !this.productData?.sale ||
        !this.productData?.sale_value ||
        !this.productPrice
      ) {
        return 0;
      }

      return Number(
        (
          this.productPrice -
          this.productPrice * (+this.productData.sale_value / 100)
        ).toFixed(2)
      );
    },
    productLink() {
      const sizeDefaultQuery = {};
      // if (
      //   this.productData?.material_categories?.some((item) =>
      //     this.sizesSlugs.includes(item.slug)
      //   )
      // ) {
      //   sizeDefaultQuery.size = "default";
      // }
      if (this.productData.embedded) {
        return this.localePath({
          path: `/product/${this.productData?.slug}`,
          // query: {
          //   config: this.productSKU,
          // },
        });
      }
      return this.localePath({
        path: `/product/${this.productData?.slug}`,
        query: {
          configuration_id: this.productSKU,
          ...sizeDefaultQuery,
        },
      });
    },
    sizeGuideUrl() {
      const categorySizeGuide = this.productData?.categories?.find(
        (category) => category.mixedTranslation?.size_chart?.url
      );

      return (
        this.productData.mixedTranslation?.size_chart?.url ||
        categorySizeGuide?.mixedTranslation?.size_chart?.url ||
        ""
      );
    },
    materialCategoriesDictionary() {
      return (
        this.productData?.material_categories?.reduce((acc, el) => {
          acc[el.sku] = el.slug;
          return acc;
        }, {}) || {}
      );
    },
    isOutOfStock() {
      const configurationOutOfStock =
        this.configurationInfo?.track_inventory &&
        !this.configurationInfo?.stock_qty;
      return Boolean(
        configurationOutOfStock || !this.finalConfigurationPriceValue
      );
    },
    isConfigurationInventoryOutOfStockPlp() {
      if (this.productData?.variant_plp_image) {
        return Boolean(
          !this.productData?.variant_plp_image?.stock_qty &&
            this.productData?.variant_plp_image?.track_inventory
        );
      } else if (this.productData?.default_config) {
        return Boolean(
          !this.productData?.default_config?.stock_qty &&
            this.productData?.default_config?.track_inventory
        );
      } else {
        return false;
      }
    },
    isConfigurationOutOfStockPlp() {
      return !this.productPrice || this.isConfigurationInventoryOutOfStockPlp;
    },
  },
};
