import { format } from "date-fns";
import { integrationsConfig } from "~/config";

export const state = () => ({
  breadcrumbs: null,
  isOpenSideMenu: false,
  isOpenCartDrawer: false,
  isIndivLoaded: false,
  settings: null,
  integrations: null,
  languages: [],
  menu: null,
  desktopMenu: null,
  isOpenMobileMenu: false,
  material_categories: [],
  collectionList: [],
  filtersData: [],
  integrationCallbackStatuses: {
    TYPE_GOOGLE_ANALYTICS: false,
    TYPE_FACEBOOK_PIXEL: false,
  },
  appBreakpoints: {
    height: 334,
    width: 991,
    xs: false,
    sm: false,
    md: true,
    lg: false,
    xl: false,
    name: "md",
    smAndUp: true,
    mdAndUp: true,
    lgAndUp: false,
    xlAndUp: false,
    smAndDown: false,
    mdAndDown: true,
    lgAndDown: true,
  },
  sitePopup: null,
  discountCampaignsData: {
    topBanner: null,
    popups: [],
    banners: [],
  },
  paymentMethodsData: null,
  cacheVersions: null,
  jsonSchemaData: {
    pageName: "",
    pageImage: "",

    numberOfItems: 0,
    products: null,
    product: null,
    productUrl: "",
    productTitle: "",
    productDescription: "",
    productPrice: "",
    productSku: "",
    productImage: "",

    posts: null,
    post: null,
  },
  subscriptionFormData: null,
});

export const getters = {
  getIntegrationCallbackStatuses(state) {
    return state.integrationCallbackStatuses;
  },
  getBreadcrumbs(state) {
    return state.breadcrumbs;
  },
  getCompanyName(state) {
    return state.settings.company_name || "Omara";
  },
  getMaterialsCategories(state) {
    return state.material_categories;
  },
  getCollectionList(state) {
    return state.collectionList;
  },
  getFiltersData(state) {
    return state.filtersData;
  },
  stripeIntegration(state) {
    return state.integrations[
      integrationsConfig.integrationsType.TYPE_STRIPE_AFFIRM
    ];
  },
  getAppBreakpoints(state) {
    return state.appBreakpoints;
  },
  getLanguages(state) {
    return state.languages || [];
  },
  getDefaultLangId(state, getters) {
    return (getters.getLanguages || []).find((lang) => lang.is_default)?.id;
  },
  getCurrentLangId(state, getters) {
    return (locale) => {
      const currentLangId = (getters.getLanguages || []).find(
        (lang) => lang.slug === locale
      )?.id;
      return currentLangId || getters.getDefaultLangId;
    };
  },
  getSettings(state) {
    return state.settings;
  },
  getSystemDateFormat(state) {
    function isCorrectFormat(settingsDateFormat) {
      try {
        format(new Date(), settingsDateFormat);
      } catch (err) {
        console.log("System settings Date format error", err);
        return false;
      }
      return true;
    }
    return state.settings?.date_format &&
      isCorrectFormat(state.settings.date_format)
      ? state.settings.date_format
      : "dd.MM.yyy";
  },
  getDiscountCampaignsData(state) {
    return state.discountCampaignsData;
  },
  getPaymentMethodsData(state) {
    return state.paymentMethodsData;
  },
  getCacheVersion(state) {
    return (key) => state.cacheVersions?.[key] || "";
  },
  getZendeskChatIntegration(state) {
    return state.integrations?.[
      integrationsConfig.integrationsType.TYPE_ZENDESK_CHAT
    ];
  },
  getKlaviyoIntegration(state) {
    return state.integrations?.[
      integrationsConfig.integrationsType.TYPE_KLAVIYO
    ];
  },
  getOperateStatisticsIntegration(state) {
    return state.integrations?.[
      integrationsConfig.integrationsType.TYPE_OPERATE_STATISTICS
    ];
  },
  getJsonSchemaData(state) {
    return state.jsonSchemaData;
  },
  getFirebaseIntegration(state) {
    return state.integrations?.[
      integrationsConfig.integrationsType.TYPE_FIREBASE
    ];
  },
};

export const mutations = {
  SET_INTEGRATION_CALLBACK_STATUS(state, payload) {
    state.integrationCallbackStatuses[payload.integrationName] = payload.status;
  },
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
  },
  TOGGLE_SIDE_MENU(state) {
    state.isOpenSideMenu = !state.isOpenSideMenu;
  },
  TOGGLE_CART_DRAWER(state) {
    state.isOpenCartDrawer = !state.isOpenCartDrawer;
  },
  CLOSE_CART_DRAWER(state) {
    state.isOpenCartDrawer = false;
  },
  SET_INDIV_LOADED(state, payload) {
    state.isIndivLoaded = payload;
  },
  SET_BOOTSTRAP_DATA(state, payload) {
    state.settings = payload.data.settings;
    state.menu = payload.data.menu;
    state.integrations = payload.data.integrations;
    state.languages = payload.data.languages;
    const material_categories = payload.data.material_categories;
    material_categories.sort((a, b) => a.sort_order - b.sort_order);
    state.material_categories = material_categories;
  },
  SET_DESKTOP_MENU(state, payload) {
    state.desktopMenu = payload;
  },
  SET_SIDE_MENU(state, payload) {
    state.isOpenSideMenu = payload;
  },
  TOGGLE_MOBILE_MENU(state) {
    state.isOpenMobileMenu = !state.isOpenMobileMenu;
  },
  SET_MOBILE_MENU(state, payload) {
    state.isOpenMobileMenu = payload;
  },
  SET_COLLECTION_LIST(state, payload) {
    state.collectionList = payload;
  },
  SET_FILTERS_DATA(state, payload) {
    payload.materials.sort((a, b) => a.sort_order - b.sort_order);
    state.filtersData = payload;
  },
  SET_APP_BREAKPOINTS(state, payload) {
    state.appBreakpoints = { ...payload };
  },
  SET_LANGUAGES_DATA(state, payload) {
    state.languages = payload.data;
  },
  SET_SITE_POPUP(state, payload) {
    state.sitePopup = payload;
  },
  SET_DISCOUNT_CAMPAIGNS_DATA(state, payload) {
    state.discountCampaignsData = payload;
  },
  SET_PAYMENT_METHODS(state, payload) {
    state.paymentMethodsData = payload;
  },
  SET_CACHE_VERSIONS(state, payload) {
    state.cacheVersions = payload;
  },
  SET_JSON_SCHEMA_DATA(state, payload) {
    state.jsonSchemaData = { ...state.jsonSchemaData, ...payload };
  },
  SET_SUBSCRIPTION_FROM_DATA(state, payload) {
    state.subscriptionFormData = payload;
  },
};

export const actions = {
  setIntegrationStatusByObjectName({ commit, state }, payload) {
    if (typeof window === "undefined") {
      return;
    }
    let intervalCount = 0;
    let interval = 0;
    interval = setInterval(() => {
      intervalCount++;
      if (intervalCount > 30) {
        clearInterval(interval);
      }
      if (window[payload.integrationObjectName]) {
        clearInterval(interval);
        commit("SET_INTEGRATION_CALLBACK_STATUS", {
          integrationName: payload.integrationName,
          status: true,
        });
      }
    }, 500);
  },
  async fetchCollectionList({ commit, state }) {
    if (state.collectionList.length) {
      return;
    }
    try {
      const { data } = await this.$api.products.getCollectionsList();
      commit("SET_COLLECTION_LIST", data.data);
    } catch (err) {
      console.log("err - ", err?.response?.data?.errors);
      // error({ statusCode: err.response.status });
    }
  },
  async fetchFiltersData({ commit, state }) {
    if (state.filtersData && Object.keys(state.filtersData)?.length) {
      return;
    }
    try {
      const { data } = await this.$api.general.getFilters();
      data.data.categories.sort((a, b) => a.sort_order - b.sort_order);
      commit("SET_FILTERS_DATA", data.data);
    } catch (err) {
      console.log("err - ", err?.response?.data?.errors);
    }
  },
  setBreadcrumbs({ commit }, payload) {
    commit("SET_BREADCRUMBS", payload);
  },
  async getSiteSettings({ commit, state, dispatch }) {
    try {
      const { data: languagesData } = await this.$api.general.getLanguages();
      commit("SET_LANGUAGES_DATA", languagesData);

      const promises = [
        this.$api.general.getBootstrap(),
        this.$api.general.getPaymentMethodsList(),
        this.$api.pages.getBlok("footer-form"),
      ];

      const accessToken = this.$cookies.get("access_token");
      if (accessToken) {
        dispatch(
          "auth/setUserInfo",
          { access_token: accessToken },
          { root: true }
        );
        promises.push(dispatch("auth/fetchUserInfo", null, { root: true }));
      }
      const [
        { data },
        { data: getPaymentMethodsListData },
        { data: subscriptionsFormData },
      ] = await Promise.all(promises);

      commit("SET_BOOTSTRAP_DATA", data);
      commit("SET_PAYMENT_METHODS", getPaymentMethodsListData?.data);
      commit("SET_SUBSCRIPTION_FROM_DATA", subscriptionsFormData?.data);
    } catch (err) {
      console.error("err");
    }
  },
  setSitePopup({ commit }, payload) {
    commit("SET_SITE_POPUP", payload);
  },
  setDiscountCampaignsData({ commit }, payload) {
    commit("SET_DISCOUNT_CAMPAIGNS_DATA", payload);
  },
};
