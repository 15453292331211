export const state = () => ({
  userInfo: null,
});

export const getters = {
  isAuthenticated(state) {
    return !!state.userInfo;
  },
  getToken(state) {
    return state.userInfo?.access_token?.token;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
};

export const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },
};

export const actions = {
  setUserInfo({ commit }, payload) {
    commit("SET_USER_INFO", payload);
    if (payload?.access_token) {
      this.$cookies.set("access_token", payload.access_token);
    }
  },
  removeToken({ commit }) {
    commit("SET_USER_INFO", null);
    this.$cookies.remove("access_token");
  },
  async fetchUserInfo({ commit, dispatch }) {
    try {
      const res = await this.$api.user.getUserData();
      const accessToken = this.$cookies.get("access_token");

      const payload = {
        ...res.data.data,
        access_token: accessToken,
      };
      dispatch("setUserInfo", payload);
    } catch (error) {
      console.error("fetchUserInfo error", error);
    }
  },
};
