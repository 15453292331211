import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ed54a2f0 = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _107d21ba = () => interopDefault(import('../pages/articles.vue' /* webpackChunkName: "pages/articles" */))
const _2f73f4bd = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _605ed04e = () => interopDefault(import('../pages/articles/_section/index.vue' /* webpackChunkName: "pages/articles/_section/index" */))
const _5ea82f06 = () => interopDefault(import('../pages/articles/_section/_slug.vue' /* webpackChunkName: "pages/articles/_section/_slug" */))
const _8e808f40 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _0ac60faf = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _29baf886 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _0f3656aa = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _24a33db6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _30a9fe53 = () => interopDefault(import('../pages/journey.vue' /* webpackChunkName: "pages/journey" */))
const _8c8af582 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _e107a170 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _b7daf484 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _6d828abc = () => interopDefault(import('../pages/checkout/payout.vue' /* webpackChunkName: "pages/checkout/payout" */))
const _550ede7c = () => interopDefault(import('../pages/profile/order-tracking/index.vue' /* webpackChunkName: "pages/profile/order-tracking/index" */))
const _fc61da70 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _e82c1ab4 = () => interopDefault(import('../pages/profile/shipping-addresses.vue' /* webpackChunkName: "pages/profile/shipping-addresses" */))
const _03b25262 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _57c74932 = () => interopDefault(import('../pages/profile/order-tracking/_id/index.vue' /* webpackChunkName: "pages/profile/order-tracking/_id/index" */))
const _20bf5a70 = () => interopDefault(import('../pages/cart/_id.vue' /* webpackChunkName: "pages/cart/_id" */))
const _090f6e67 = () => interopDefault(import('../pages/catalog/_slug.vue' /* webpackChunkName: "pages/catalog/_slug" */))
const _3a99646e = () => interopDefault(import('../pages/checkout/_id.vue' /* webpackChunkName: "pages/checkout/_id" */))
const _35c5794e = () => interopDefault(import('../pages/collection/_slug/index.vue' /* webpackChunkName: "pages/collection/_slug/index" */))
const _53c94c80 = () => interopDefault(import('../pages/product/_slug/index.vue' /* webpackChunkName: "pages/product/_slug/index" */))
const _5eeb571b = () => interopDefault(import('../pages/recover-password/_token/index.vue' /* webpackChunkName: "pages/recover-password/_token/index" */))
const _6531ec34 = () => interopDefault(import('../pages/verify/_token/index.vue' /* webpackChunkName: "pages/verify/_token/index" */))
const _7c05809c = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/500",
    component: _ed54a2f0,
    name: "500___sk"
  }, {
    path: "/articles",
    component: _107d21ba,
    children: [{
      path: "",
      component: _2f73f4bd,
      name: "articles___sk"
    }, {
      path: ":section",
      component: _605ed04e,
      name: "articles-section___sk"
    }, {
      path: ":section/:slug",
      component: _5ea82f06,
      name: "articles-section-slug___sk"
    }]
  }, {
    path: "/cart",
    component: _8e808f40,
    name: "cart___sk"
  }, {
    path: "/catalog",
    component: _0ac60faf,
    name: "catalog___sk"
  }, {
    path: "/checkout",
    component: _29baf886,
    name: "checkout___sk"
  }, {
    path: "/collections",
    component: _0f3656aa,
    name: "collections___sk"
  }, {
    path: "/cs",
    component: _24a33db6,
    name: "index___cs"
  }, {
    path: "/en",
    component: _24a33db6,
    name: "index___en"
  }, {
    path: "/journey",
    component: _30a9fe53,
    name: "journey___sk"
  }, {
    path: "/pl",
    component: _24a33db6,
    name: "index___pl"
  }, {
    path: "/profile",
    component: _8c8af582,
    name: "profile___sk"
  }, {
    path: "/sign-in",
    component: _e107a170,
    name: "sign-in___sk"
  }, {
    path: "/sign-up",
    component: _b7daf484,
    name: "sign-up___sk"
  }, {
    path: "/checkout/payout",
    component: _6d828abc,
    name: "checkout-payout___sk"
  }, {
    path: "/cs/500",
    component: _ed54a2f0,
    name: "500___cs"
  }, {
    path: "/cs/articles",
    component: _107d21ba,
    children: [{
      path: "",
      component: _2f73f4bd,
      name: "articles___cs"
    }, {
      path: ":section",
      component: _605ed04e,
      name: "articles-section___cs"
    }, {
      path: ":section/:slug",
      component: _5ea82f06,
      name: "articles-section-slug___cs"
    }]
  }, {
    path: "/cs/cart",
    component: _8e808f40,
    name: "cart___cs"
  }, {
    path: "/cs/catalog",
    component: _0ac60faf,
    name: "catalog___cs"
  }, {
    path: "/cs/checkout",
    component: _29baf886,
    name: "checkout___cs"
  }, {
    path: "/cs/collections",
    component: _0f3656aa,
    name: "collections___cs"
  }, {
    path: "/cs/journey",
    component: _30a9fe53,
    name: "journey___cs"
  }, {
    path: "/cs/profile",
    component: _8c8af582,
    name: "profile___cs"
  }, {
    path: "/cs/sign-in",
    component: _e107a170,
    name: "sign-in___cs"
  }, {
    path: "/cs/sign-up",
    component: _b7daf484,
    name: "sign-up___cs"
  }, {
    path: "/en/500",
    component: _ed54a2f0,
    name: "500___en"
  }, {
    path: "/en/articles",
    component: _107d21ba,
    children: [{
      path: "",
      component: _2f73f4bd,
      name: "articles___en"
    }, {
      path: ":section",
      component: _605ed04e,
      name: "articles-section___en"
    }, {
      path: ":section/:slug",
      component: _5ea82f06,
      name: "articles-section-slug___en"
    }]
  }, {
    path: "/en/cart",
    component: _8e808f40,
    name: "cart___en"
  }, {
    path: "/en/catalog",
    component: _0ac60faf,
    name: "catalog___en"
  }, {
    path: "/en/checkout",
    component: _29baf886,
    name: "checkout___en"
  }, {
    path: "/en/collections",
    component: _0f3656aa,
    name: "collections___en"
  }, {
    path: "/en/journey",
    component: _30a9fe53,
    name: "journey___en"
  }, {
    path: "/en/profile",
    component: _8c8af582,
    name: "profile___en"
  }, {
    path: "/en/sign-in",
    component: _e107a170,
    name: "sign-in___en"
  }, {
    path: "/en/sign-up",
    component: _b7daf484,
    name: "sign-up___en"
  }, {
    path: "/pl/500",
    component: _ed54a2f0,
    name: "500___pl"
  }, {
    path: "/pl/articles",
    component: _107d21ba,
    children: [{
      path: "",
      component: _2f73f4bd,
      name: "articles___pl"
    }, {
      path: ":section",
      component: _605ed04e,
      name: "articles-section___pl"
    }, {
      path: ":section/:slug",
      component: _5ea82f06,
      name: "articles-section-slug___pl"
    }]
  }, {
    path: "/pl/cart",
    component: _8e808f40,
    name: "cart___pl"
  }, {
    path: "/pl/catalog",
    component: _0ac60faf,
    name: "catalog___pl"
  }, {
    path: "/pl/checkout",
    component: _29baf886,
    name: "checkout___pl"
  }, {
    path: "/pl/collections",
    component: _0f3656aa,
    name: "collections___pl"
  }, {
    path: "/pl/journey",
    component: _30a9fe53,
    name: "journey___pl"
  }, {
    path: "/pl/profile",
    component: _8c8af582,
    name: "profile___pl"
  }, {
    path: "/pl/sign-in",
    component: _e107a170,
    name: "sign-in___pl"
  }, {
    path: "/pl/sign-up",
    component: _b7daf484,
    name: "sign-up___pl"
  }, {
    path: "/profile/order-tracking",
    component: _550ede7c,
    name: "profile-order-tracking___sk"
  }, {
    path: "/profile/orders",
    component: _fc61da70,
    name: "profile-orders___sk"
  }, {
    path: "/profile/shipping-addresses",
    component: _e82c1ab4,
    name: "profile-shipping-addresses___sk"
  }, {
    path: "/cs/checkout/payout",
    component: _6d828abc,
    name: "checkout-payout___cs"
  }, {
    path: "/cs/profile/order-tracking",
    component: _550ede7c,
    name: "profile-order-tracking___cs"
  }, {
    path: "/cs/profile/orders",
    component: _fc61da70,
    name: "profile-orders___cs"
  }, {
    path: "/cs/profile/shipping-addresses",
    component: _e82c1ab4,
    name: "profile-shipping-addresses___cs"
  }, {
    path: "/en/checkout/payout",
    component: _6d828abc,
    name: "checkout-payout___en"
  }, {
    path: "/en/profile/order-tracking",
    component: _550ede7c,
    name: "profile-order-tracking___en"
  }, {
    path: "/en/profile/orders",
    component: _fc61da70,
    name: "profile-orders___en"
  }, {
    path: "/en/profile/shipping-addresses",
    component: _e82c1ab4,
    name: "profile-shipping-addresses___en"
  }, {
    path: "/pl/checkout/payout",
    component: _6d828abc,
    name: "checkout-payout___pl"
  }, {
    path: "/pl/profile/order-tracking",
    component: _550ede7c,
    name: "profile-order-tracking___pl"
  }, {
    path: "/pl/profile/orders",
    component: _fc61da70,
    name: "profile-orders___pl"
  }, {
    path: "/pl/profile/shipping-addresses",
    component: _e82c1ab4,
    name: "profile-shipping-addresses___pl"
  }, {
    path: "/cs/checkout/success/:id?",
    component: _03b25262,
    name: "checkout-success-id___cs"
  }, {
    path: "/cs/profile/order-tracking/:id",
    component: _57c74932,
    name: "profile-order-tracking-id___cs"
  }, {
    path: "/en/checkout/success/:id?",
    component: _03b25262,
    name: "checkout-success-id___en"
  }, {
    path: "/en/profile/order-tracking/:id",
    component: _57c74932,
    name: "profile-order-tracking-id___en"
  }, {
    path: "/pl/checkout/success/:id?",
    component: _03b25262,
    name: "checkout-success-id___pl"
  }, {
    path: "/pl/profile/order-tracking/:id",
    component: _57c74932,
    name: "profile-order-tracking-id___pl"
  }, {
    path: "/checkout/success/:id?",
    component: _03b25262,
    name: "checkout-success-id___sk"
  }, {
    path: "/cs/cart/:id",
    component: _20bf5a70,
    name: "cart-id___cs"
  }, {
    path: "/cs/catalog/:slug",
    component: _090f6e67,
    name: "catalog-slug___cs"
  }, {
    path: "/cs/checkout/:id",
    component: _3a99646e,
    name: "checkout-id___cs"
  }, {
    path: "/cs/collection/:slug",
    component: _35c5794e,
    name: "collection-slug___cs"
  }, {
    path: "/cs/product/:slug",
    component: _53c94c80,
    name: "product-slug___cs"
  }, {
    path: "/cs/recover-password/:token",
    component: _5eeb571b,
    name: "recover-password-token___cs"
  }, {
    path: "/cs/verify/:token",
    component: _6531ec34,
    name: "verify-token___cs"
  }, {
    path: "/en/cart/:id",
    component: _20bf5a70,
    name: "cart-id___en"
  }, {
    path: "/en/catalog/:slug",
    component: _090f6e67,
    name: "catalog-slug___en"
  }, {
    path: "/en/checkout/:id",
    component: _3a99646e,
    name: "checkout-id___en"
  }, {
    path: "/en/collection/:slug",
    component: _35c5794e,
    name: "collection-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _53c94c80,
    name: "product-slug___en"
  }, {
    path: "/en/recover-password/:token",
    component: _5eeb571b,
    name: "recover-password-token___en"
  }, {
    path: "/en/verify/:token",
    component: _6531ec34,
    name: "verify-token___en"
  }, {
    path: "/pl/cart/:id",
    component: _20bf5a70,
    name: "cart-id___pl"
  }, {
    path: "/pl/catalog/:slug",
    component: _090f6e67,
    name: "catalog-slug___pl"
  }, {
    path: "/pl/checkout/:id",
    component: _3a99646e,
    name: "checkout-id___pl"
  }, {
    path: "/pl/collection/:slug",
    component: _35c5794e,
    name: "collection-slug___pl"
  }, {
    path: "/pl/product/:slug",
    component: _53c94c80,
    name: "product-slug___pl"
  }, {
    path: "/pl/recover-password/:token",
    component: _5eeb571b,
    name: "recover-password-token___pl"
  }, {
    path: "/pl/verify/:token",
    component: _6531ec34,
    name: "verify-token___pl"
  }, {
    path: "/profile/order-tracking/:id",
    component: _57c74932,
    name: "profile-order-tracking-id___sk"
  }, {
    path: "/cart/:id",
    component: _20bf5a70,
    name: "cart-id___sk"
  }, {
    path: "/catalog/:slug",
    component: _090f6e67,
    name: "catalog-slug___sk"
  }, {
    path: "/checkout/:id",
    component: _3a99646e,
    name: "checkout-id___sk"
  }, {
    path: "/collection/:slug",
    component: _35c5794e,
    name: "collection-slug___sk"
  }, {
    path: "/product/:slug",
    component: _53c94c80,
    name: "product-slug___sk"
  }, {
    path: "/recover-password/:token",
    component: _5eeb571b,
    name: "recover-password-token___sk"
  }, {
    path: "/verify/:token",
    component: _6531ec34,
    name: "verify-token___sk"
  }, {
    path: "/en/*",
    component: _7c05809c,
    name: "all___en"
  }, {
    path: "/pl/*",
    component: _7c05809c,
    name: "all___pl"
  }, {
    path: "/cs/*",
    component: _7c05809c,
    name: "all___cs"
  }, {
    path: "/",
    component: _24a33db6,
    name: "index___sk"
  }, {
    path: "/*",
    component: _7c05809c,
    name: "all___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
