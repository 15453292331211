export const integrationsConfig = {
  integrationsType: {
    TYPE_GOOGLE_ANALYTICS: 1,
    TYPE_FACEBOOK_PIXEL: 2,
    TYPE_HOTJAR: 3,
    TYPE_ZENDESK_CHAT: 4,
    TYPE_KLAVIYO: 5,
    TYPE_STRIPE_AFFIRM: 6,
    TYPE_PAYPAL: 7,
    TYPE_CDN_DOMAIN: 8,
    TYPE_CUSTOM_SCRIPTS: 9,
    TYPE_OPERATE_STATISTICS: 35,
    TYPE_IMPACT: 60,
    TYPE_FIREBASE: 63,
  },
};
