import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      integrationCallbackStatuses: "general/getIntegrationCallbackStatuses",
      getCompanyName: "general/getCompanyName",
    }),
    storeSettings() {
      return this.$store.state.general.settings;
    },
    isFacebookPixelAvailable() {
      return (
        this.integrationCallbackStatuses.TYPE_FACEBOOK_PIXEL && window?.fbq
      );
    },
  },
  methods: {
    fbPriceFormat(price) {
      price = price || 0;
      return Number(price).toFixed(2);
    },
    fbViewContentEvent(data, configurationData) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "ViewContent", {
        content_type: "product",
        content_ids: [configurationData?.configuration_id],
        content_name: data?.mixedTranslation?.title || data?.title || "",
        content_category: data.collections?.[0]?.mixedTranslation?.title || "",
        value: this.fbPriceFormat(configurationData?.cost || data.base_cost),
        currency: this.storeSettings?.currency_code || "",
      });
    },
    fbAddToCartEvent(data, configurationData) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "AddToCart", {
        content_type: "product",
        content_ids: [configurationData?.configuration_id],
        content_name: data?.mixedTranslation?.title || data?.title || "",
        content_category: data.collections?.[0]?.mixedTranslation?.title || "",
        value: this.fbPriceFormat(configurationData?.cost || data.base_cost),
        currency: this.storeSettings?.currency_code || "",
      });
    },
    fbInitiateCheckoutEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "InitiateCheckout", {
        content_type: "product",
        content_name: this.$t("checkout"),
        value: this.fbPriceFormat(data.final_amount),
        currency: this.storeSettings?.currency_code || "",
        num_items: data.items.reduce((acc, item) => acc + item.quantity, 0),
        content_ids: data.items?.map((item) => item.configuration_id),
      });
    },
    fbPurchaseEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "Purchase", {
        content_type: "product",
        content_name: this.$t("thanksOrder"),
        value: this.fbPriceFormat(data.final_amount),
        currency: data.currency_code,
        num_items: data.order_products.reduce(
          (acc, item) => acc + item.quantity,
          0
        ),
        content_ids: data.order_products?.map((item) => item.configuration_id),
      });
    },
    fbViewCartEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "view_cart", {
        value: this.fbPriceFormat(data?.final_amount), // сума транзакції
        currency: this.storeSettings?.currency_code || "", // валюта, якщо гривні, то UAH, якщо доллар, то USD
        items: data?.items?.map((item) => ({
          item_name: item?.mixedTranslation?.title, // назва товару
          item_id: item.id, // ідентифікатор товару
          price: this.fbPriceFormat(item.base_cost), // ціна товару
          item_brand: this.getCompanyName, // бренд товару
          item_category: "", // категорія товару
          item_variant: item.configuration_id, // варіант товару
          quantity: item.quantity, // кількість замовленого товару
        })),
      });
    },
    fbViewSearchResultEvent(data) {
      if (!this.isFacebookPixelAvailable || !data) {
        return;
      }
      window.fbq("track", "view_search_results", {
        search_term: data,
      });
    },
    fbGenerateLeadEvent(lead_source) {
      if (!this.isFacebookPixelAvailable || !lead_source) {
        return;
      }
      window.fbq("track", "generate_lead", {
        currency: this.storeSettings?.currency_code || "",
        value: 0,
        lead_source,
      });
    },
    fbBeginCheckoutEvent(data) {
      if (!this.isFacebookPixelAvailable || !data?.length) {
        return;
      }
      window.fbq("track", "begin_checkout", {
        items: data.map((item, index) => ({
          item_name: data?.mixedTranslation?.title || data?.title, // назва товару
          item_id: item.id, // ідентифікатор товару
          price: this.fbPriceFormat(item.base_cost), // ціна товару
          item_brand: this.getCompanyName, // бренд товару

          item_category: "", // категорія товару

          item_category2: "", // другий рівень категорії товару
          item_category3: "", // третій рівень категорії товару
          item_category4: "", // четвертий рівень категорії товару
          item_variant: item.configuration_id, // варіант товару

          item_list_name: "", // назва списку товарів

          item_list_id: item.configuration_id, // унікальний ідентифікатор переліку товарі
          index: index + 1, // позиція товару у переліку товарів
          quantity: item.quantity, // кількість замовленого товару
        })),
      });
    },
    fbAddToWishlistEvent(product) {
      if (!this.isFacebookPixelAvailable || !product) {
        return;
      }
      window.fbq("track", "add_to_wishlist", {
        currency: this.storeSettings?.currency_code || "",
        value: product.product_price,
        items: [
          {
            item_name: product?.product_title, // назва товару
            item_id: product?.product_id, // ідентифікатор товару
            price: this.fbPriceFormat(product.product_price), // ціна товару
            item_brand: this.getCompanyName, // бренд товару
            item_category: "", // категорія товару
            item_variant: product.configuration_id, // варіант товару
          },
        ],
      });
    },
    fbRemoveFromWishlistEvent(product) {
      if (!this.isFacebookPixelAvailable || !product) {
        return;
      }
      window.fbq("track", "favorites_remove", {
        currency: this.storeSettings?.currency_code || "",
        value: product.product_price,
        items: [
          {
            item_name: product?.product_title, // назва товару
            item_id: product?.product_id, // ідентифікатор товару
            price: this.fbPriceFormat(product.product_price), // ціна товару
            item_brand: this.getCompanyName, // бренд товару
            item_category: "", // категорія товару
            item_variant: product.configuration_id, // варіант товару
          },
        ],
      });
    },
    fbViewWishlistEvent(products) {
      if (!this.isFacebookPixelAvailable || !products) {
        return;
      }
      window.fbq("track", "favorites_view", {
        currency: this.storeSettings?.currency_code || "",
        value: products.reduce(
          (acc, product) => acc + Number(product.price),
          0
        ),
        items: products.map((product) => ({
          item_name: product?.mixedTranslation?.title, // назва товару
          item_id: product?.mixedTranslation?.id, // ідентифікатор товару
          price: this.fbPriceFormat(product.price), // ціна товару
          item_brand: this.getCompanyName, // бренд товару
          item_category: "", // категорія товару
          item_variant: product.sku, // варіант товару
        })),
      });
    },
  },
};
