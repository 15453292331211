import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";

export default ({ app, store }, inject) => {
  const firebaseIntegration = store.getters["general/getFirebaseIntegration"];
  let firebaseApp = null;
  let auth = null;
  if (firebaseIntegration) {
    firebaseApp = initializeApp(firebaseIntegration.sdk_config);
    auth = getAuth(firebaseApp);
  }
  // Inject $socialAuth
  inject("socialAuth", {
    auth,
    firebaseApp,
    signOut: async () => {
      try {
        if (auth) {
          await signOut(auth);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });
};
