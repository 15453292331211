
import textLinkMixin from "~/mixins/textLink.js";
import UiLazyImg from "~/components/UI/UiLazyImg.vue";
import UiLazyVideo from "~/components/UI/UiLazyVideo.vue";

export default {
  name: "PromotionBanner",
  components: {
    UiLazyImg,
    UiLazyVideo,
  },
  mixins: [textLinkMixin],
  props: {
    redirectTo: {
      type: String,
      required: false,
    },
    imgFile: {
      type: Object,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
  computed: {
    isImage() {
      return this.imgFile?.media_type?.split("/")[0] === "image";
    },
    isVideo() {
      return this.imgFile?.media_type?.split("/")[0] === "video";
    },
  },
};
