
import { mapGetters } from "vuex";
import { isEqual } from "lodash";
import ProductCard from "~/components/common/ProductCard";
import PromotionBanner from "~/components/common/PromotionBanner";
import UiPagination from "~/components/UI/UiPagination";
import UiSpinner from "@/components/UI/UiSpinner.vue";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";
import PrevArrowSvg from "~/components/svg/PrevArrowSvg.vue";
import jsonLdMixin from "~/mixins/jsonLdMixin";
import { getImageByPosition } from "~/utils";

export default {
  name: "CatalogBlock",
  components: {
    PrevArrowSvg,
    UiPagination,
    ProductCard,
    UiSpinner,
    PromotionBanner,
  },
  mixins: [googleAnalyticsEvents, jsonLdMixin],
  props: {
    currentCollection: {
      type: Object,
      default: () => {},
    },
    filterData: {
      type: Object,
      default: () => {},
    },
    pageData: {
      type: Object,
      default: () => {},
    },
    blockData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      catalogData: [],
      currentPage: 1,
      itemsOnPage: 20,
      totalPages: 1,
      loading: false,
      loadingTop: false,
      loadMoreLoading: false,
      catalogDataLoaded: false,
      observer: null,
      topObserver: null,
      isMounted: false,
      scrollPosition: 0,
      SCROLL_POSITION_TO_SHOW_ARROW: 1500,
      catalogBottomPosition: 0,
      windowHeight: 0,
      totalItems: 0,
      banners: [],
      bannerParams: [],
      itemsPageObserver: null,
      lowerFetchedPage: Number(this.$route.query.page || 1),
    };
  },
  async fetch() {
    this.currentPage = Number(this.$route?.query?.page || 1);
    this.loading = true;
    await this.getCatalog(false);
    this.loading = false;
  },
  head() {
    const jsonLdPayload = this.setPLPPageLdSchema();
    return {
      script: [
        {
          hid: this.getJsonLdScriptHid(jsonLdPayload),
          type: "application/ld+json",
          json: jsonLdPayload,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDiscountCampaignsData: "general/getDiscountCampaignsData",
    }),
    statisticItemListName() {
      const listName =
        this.pageData?.mixedTranslation?.title ||
        this.currentCollection?.mixedTranslation?.title ||
        this.$route.query.search;
      return listName || this.$t("catalog");
    },
    canSendGoogleAnalyticsStatistic() {
      return (
        this.integrationCallbackStatuses.TYPE_GOOGLE_ANALYTICS &&
        this.catalogDataLoaded
      );
    },
    catalogSlug() {
      const categoriesLength = this.blockData?.categories?.length;
      const materialsLength = this.blockData?.materials?.length;
      return !this.$route.query.search && // TODO search (collectionsLength === 1 || this.currentCollection) &&
        !categoriesLength &&
        !materialsLength
        ? this.$route?.params?.slug ||
            this.currentCollection?.slug ||
            (this.blockData?.collections &&
            this.blockData?.collections?.length === 1
              ? this.blockData?.collections[0].slug
              : "")
        : "";
    },
    requestParams() {
      let params = {
        page: this.currentPage,
        count: this.itemsOnPage,
        // search: this.$route.query.search, // TODO search

        categories_sku: Array.isArray(this.$route.query?.categories)
          ? this.$route.query?.categories
          : this.$route.query?.categories
          ? [this.$route.query?.categories]
          : undefined,
        materials_sku: Array.isArray(this.$route.query?.materials)
          ? this.$route.query?.materials
          : this.$route.query?.materials
          ? [this.$route.query?.materials]
          : undefined,
        min_cost_from: this.$route.query?.min_cost_from || undefined,
        max_cost_to: this.$route.query?.max_cost_to || undefined,
      };
      const categoriesLength = this.blockData?.categories?.length;
      const collectionsLength = this.blockData?.collections?.length;
      const materialsLength = this.blockData?.materials?.length;
      if (categoriesLength) {
        params.categories = this.blockData?.categories.map((m) => m.id);
      }
      if (collectionsLength) {
        params.collections = this.blockData?.collections.map((m) => m.id);
      }
      if (materialsLength) {
        params.materials_sku = [
          ...(params.materials_sku || []),
          ...this.blockData?.materials?.map((m) => m.sku),
        ];
      }

      if (this.$route.query?.sortBy) {
        const [column, order] = this.$route.query.sortBy.split("|");
        params.column = column || this.$route.query.sortBy;
        params.order_by = order || "desc";
      } else {
        params.column = this.catalogSlug ? "collection_sort_order" : undefined;
        params.order_by = "asc";
      }

      if (this.$route.query.search) {
        // TODO search
        params = {
          page: this.currentPage,
          count: this.itemsOnPage,
          search: this.$route.query.search,
        };
        if (this.$route.query?.sortBy) {
          const [column, order] = this.$route.query.sortBy.split("|");
          params.column = column || this.$route.query.sortBy;
          params.order_by = order || "desc";
        } else {
          params.column = undefined;
          params.order_by = "asc";
        }
      }

      return params;
    },
    canInitTopObserver() {
      return (
        this.isMounted &&
        !this.loading &&
        !this.loadingTop &&
        !this.loadMoreLoading
      );
    },
    catalogWithBanner() {
      if (!this.bannerParams) {
        return [...this.catalogData];
      }

      const catalog = [...this.catalogData];

      this.bannerParams.forEach((banner) => {
        if (catalog.length > banner.insertIndex) {
          catalog.splice(banner.insertIndex, 0, {
            isBanner: true,
            ...banner,
          });
        }
      });

      return catalog;
    },

    watchedQuery() {
      return {
        materials: this.$route.query.materials,
        sortBy: this.$route.query.sortBy,
        categories: this.$route.query.categories,
        collections: this.$route.query.collections,
        min_cost_from: this.$route.query.min_cost_from,
        max_cost_to: this.$route.query.max_cost_to,
        search: this.$route.query.search,
      };
    },
  },
  watch: {
    catalogData: {
      handler() {
        this.calculateBannerParams();
      },
      deep: true,
    },
    canSendGoogleAnalyticsStatistic: {
      handler(val) {
        if (val) {
          this.gaViewItemListEvent(this.catalogData, {
            item_list_name: this.statisticItemListName,
          });
          this.setGaViewCatalogEvent();
        }
      },
      immediate: true,
    },
    canInitTopObserver: {
      handler(val) {
        if (val && !this.topObserver) {
          this.createTopObserver();
        }
      },
      once: true,
    },
    watchedQuery: {
      async handler(val, old) {
        if (!isEqual(val, old)) {
          this.currentPage = 1;
          this.scrollTopToList();
          this.loading = true;
          await this.getCatalog(false);
          this.loading = false;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.isMounted = true;
    this.banners = JSON.parse(
      JSON.stringify(this.getDiscountCampaignsData.banners)
    );
    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", this.calculateBannerParams);
    this.createObserver();
    this.getCatalogBottomPosition();
    this.createItemsPageObserver();
    this.calculateBannerParams();
    await this.observeProductCards(this.requestParams.page);
  },
  beforeDestroy() {
    if (this.observer && this.$refs.loadMoreRef) {
      this.observer.disconnect();
    }
    if (this.topObserver && this.$refs.loadMoreTopRef) {
      this.topObserver.disconnect();
    }
    if (this.itemsPageObserver) {
      this.itemsPageObserver.disconnect();
    }
    window.removeEventListener("scroll", this.updateScroll);
    window.removeEventListener("resize", this.calculateBannerParams);
  },
  methods: {
    async clickCallback(page) {
      const isJustScroll = this.scrollToPage(page, false);
      if (isJustScroll) {
        return;
      }

      await this.updatePage(page, true);
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page,
        },
      });
      this.loading = true;
      await this.getCatalog(false);
      this.loading = false;
    },
    updatePage(page, scrollTop) {
      this.currentPage = page;
      this.setLowerFetchedPage(page);
      if (scrollTop) {
        this.scrollTopToList();
      }
    },
    scrollTopToList() {
      this.$nextTick(() => {
        const element = document.querySelector(".collection__top-filter");

        if (element) {
          element.scrollIntoView();
        } else {
          window.scrollTo(0, 0);
        }
      });
    },
    setGaViewCatalogEvent() {
      if (!this.catalogData) return;
      if (this.$route.query.search) {
        this.gaDynamicParamsEvent(`View Search`, {
          ecomm_prodid: this.catalogData.map((item) => item.default_sku),
          ecomm_pagetype: "searchresults",
          ecomm_totalvalue: "",
          ecomm_category: "",
        });
      } else {
        this.gaDynamicParamsEvent(`View Category`, {
          ecomm_prodid: this.catalogData.map((item) => item.default_sku),
          ecomm_pagetype: "category",
          ecomm_totalvalue: "",
          ecomm_category: this.statisticItemListName,
        });
      }
    },
    async getCatalog(merge) {
      try {
        const { data } = await this.$api.products.getCatalog(
          this.requestParams,
          this.catalogSlug
        );
        if (merge) {
          this.catalogData = [
            ...this.catalogData,
            ...this.setProductCardPage(data.data, data.meta.current_page),
          ];
        } else {
          this.catalogData = this.setProductCardPage(
            data.data,
            data.meta.current_page
          );
        }
        this.totalPages = data.meta.last_page;
        this.totalItems = data.meta.total;
        this.catalogDataLoaded = true;

        this.$store.commit("general/SET_JSON_SCHEMA_DATA", {
          pageName:
            this.pageData.mixedTranslation?.title ||
            this.currentCollection.mixedTranslation?.title,
          pageImage:
            getImageByPosition(this.pageData.medias, "open-graph")?.file?.url ||
            getImageByPosition(this.pageData.medias, "page")?.file?.url ||
            getImageByPosition(this.currentCollection.medias, "open-graph")
              ?.file?.url ||
            getImageByPosition(this.currentCollection.medias, "list")?.file
              ?.url ||
            "",
          products: this.catalogData,
          numberOfItems: this.totalItems,
        });

        this.setGaViewCatalogEvent();
        await this.observeProductCards(this.requestParams.page);
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
        if (err?.response?.status === 400) {
          this.$nuxt.error({ statusCode: 404 });
        }
      }
    },
    async getCatalogTop(params) {
      try {
        const { data } = await this.$api.products.getCatalog(
          { ...this.requestParams, ...params },
          this.catalogSlug
        );
        return {
          data: this.setProductCardPage(data.data, data.meta.current_page),
          meta: data.meta,
        };
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
        if (err?.response?.status === 400) {
          this.$nuxt.error({ statusCode: 404 });
        }
      } finally {
        this.setLowerFetchedPage(params.page);
        await this.observeProductCards(params.page);
      }
    },
    createObserver() {
      if (!this.$refs.loadMoreRef) {
        return;
      }

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      this.observer = new IntersectionObserver(this.loadMoreCallback, options);
      this.observer.observe(this.$refs.loadMoreRef);
    },
    loadMoreCallback(entries) {
      entries.forEach(async (entry) => {
        if (
          entry.isIntersecting &&
          this.totalPages > this.currentPage &&
          !this.loading &&
          !this.loadingTop &&
          !this.loadMoreLoading
        ) {
          this.loadMoreLoading = true;
          this.observer.unobserve(entry.target);
          this.updatePage(this.currentPage + 1, false);
          await this.getCatalog(true);
          this.observer.observe(entry.target);
          this.loadMoreLoading = false;
          this.getCatalogBottomPosition();
        }
      });
    },
    createTopObserver() {
      if (!this.$refs.loadMoreTopRef) {
        return;
      }

      const options = {
        root: null,
        rootMargin: "400px 0px 0px 0px",
      };

      this.topObserver = new IntersectionObserver(
        this.loadMoreTopCallback,
        options
      );
      this.topObserver.observe(this.$refs.loadMoreTopRef);
    },
    createItemsPageObserver() {
      const options = {
        rootMargin: "0px",
        threshold: 0.5,
      };
      this.itemsPageObserver = new IntersectionObserver(
        this.itemsPageCallback,
        options
      );
    },
    itemsPageCallback(entries) {
      const currentPage = Number(this.$route.query.page || 1);
      const biggerPage = entries.find(
        (el) =>
          Number(el.target.getAttribute("data-item-page")) > currentPage &&
          el.isIntersecting
      );
      const allPageLessCurrent = entries.every(
        (el) =>
          Number(el.target.getAttribute("data-item-page")) < currentPage &&
          el.isIntersecting
      );

      if (allPageLessCurrent) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            page: Number(this.$route.query.page || 1) - 1,
          },
        });
      } else if (biggerPage) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            page: Number(this.$route.query.page || 1) + 1,
          },
        });
      }
    },
    observeProductCards(page) {
      if (!this.isMounted) {
        return;
      }
      setTimeout(() => {
        // setTimeout need to observe elements after they appeared
        const items = document.querySelectorAll(`[data-item-page='${page}']`);
        items.forEach((item) => {
          this.itemsPageObserver.observe(item);
        });
      }, 0);
    },
    async loadMoreTopCallback(entries) {
      if (
        entries?.[0]?.isIntersecting &&
        !this.loading &&
        !this.loadingTop &&
        !this.loadMoreLoading
      ) {
        const prevPage =
          this.requestParams.page -
          Math.ceil(this.catalogData.length / this.requestParams.count);

        if (!prevPage) {
          return;
        }
        this.loadingTop = true;

        const data = await this.getCatalogTop({ page: prevPage });

        const scrollHeight = window.document.documentElement.scrollHeight;
        const scrollTop = window.document.documentElement.scrollTop;

        this.catalogData = [...(data.data || []), ...(this.catalogData || [])];

        await this.$nextTick();

        setTimeout(() => {
          const htmlElement = document.querySelector("html");
          htmlElement.style.scrollBehavior = "auto";

          const newScrollHeight = window.document.documentElement.scrollHeight;
          window.document.documentElement.scrollTop =
            newScrollHeight - scrollHeight + scrollTop;

          htmlElement.style.scrollBehavior = "smooth";
          setTimeout(() => {
            this.loadingTop = false;
          }, 50);
        }, 70);
      }
    },
    scrollToPage(page = this.$route.query.page || 1) {
      if (page && this.catalogData.length) {
        const firstItemFromSelectedPage = document.querySelector(
          `[data-item-page='${page}']`
        );
        if (firstItemFromSelectedPage) {
          firstItemFromSelectedPage.scrollIntoView();
          return true;
        }
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      this.windowHeight = window.innerHeight;
      this.getCatalogBottomPosition();
    },
    getCatalogBottomPosition() {
      if (!this.isMounted) {
        return;
      }
      setTimeout(() => {
        this.catalogBottomPosition = this.$refs?.["products-row"]?.offsetTop;
      }, 0);
    },
    getBannerClass(banner) {
      const sizeClassMap = {
        "1x1": "product-card",
        "1x2": "banner-1x2",
        "2x2": "banner-2x2",
        "1x4": "banner-1x4",
      };
      return `${sizeClassMap[banner.size]}`;
    },
    parsePageUrl(pageUrl) {
      const url = new URL(pageUrl, window.location.origin);
      const route = url.pathname;
      const pageNumber = Number(url.searchParams.get("page") || 1);
      return {
        route,
        pageNumber,
      };
    },
    calculateBannerParams() {
      if (this.banners.length === 0) {
        return;
      }
      let skipMultiplier;
      let screenWidth;
      let totalOffset = 0;
      let totalBannerCount = 0;
      const pageIds = [];
      const bannerParams = [];
      const currentRoutePath = this.$route.path.split("/").filter(Boolean);

      if (typeof window !== "undefined") {
        screenWidth = window.innerWidth;
      }
      if (screenWidth >= 1400) {
        skipMultiplier = 4;
      } else if (screenWidth >= 768) {
        skipMultiplier = 3;
      } else {
        skipMultiplier = 2;
      }

      this.banners.forEach((banner) => {
        banner.pages.forEach((page) => {
          const { route, pageNumber } = this.parsePageUrl(page.page_link);
          if (currentRoutePath.includes(route.replace("/", ""))) {
            pageIds.push(pageNumber);
          }
        });
      });

      pageIds.sort((a, b) => a - b);

      const uniquePageIds = new Set(pageIds);

      uniquePageIds.forEach((pageId) => {
        let offset = 0;
        let firstAdjustment = 0;
        let isNewPage = true;
        let isFirstElement = true;

        this.banners
          .sort(
            (a, b) =>
              a.promotion_banner_options?.skip_rows -
              b.promotion_banner_options?.skip_rows
          )
          .filter(
            (banner) =>
              !(
                screenWidth < 1400 &&
                banner.promotion_banner_options?.size === "1x4"
              )
          )
          .forEach((banner) => {
            if (
              !banner.pages.some((page) => {
                const { route, pageNumber } = this.parsePageUrl(page.page_link);
                return (
                  pageNumber === pageId &&
                  currentRoutePath.includes(route.replace("/", ""))
                );
              })
            ) {
              return;
            }

            // Page start index
            const pageStartIndexBase =
              this.itemsOnPage * (pageId - 1) + totalBannerCount;
            const elementsOnCurrentPage =
              this.itemsOnPage * (pageId - 1) + totalOffset;
            const remainder = elementsOnCurrentPage % skipMultiplier;
            const adjustment = remainder === 0 ? 0 : skipMultiplier - remainder;

            if (isNewPage && pageId !== 1) {
              firstAdjustment = adjustment;
            }

            const pageStartIndex =
              isNewPage && pageId !== 1
                ? pageStartIndexBase + adjustment
                : pageStartIndexBase;

            // Calculate page insert index
            const bannerPosition = banner.promotion_banner_options?.position;
            const bannerSkipRows = banner.promotion_banner_options?.skip_rows;
            const [columnsOccupied, rowsOccupied] =
              banner.promotion_banner_options?.size.split("x").map(Number);
            let pageInsertIndex;

            if (
              bannerPosition === 0 ||
              (bannerPosition === 1 && rowsOccupied === 4)
            ) {
              pageInsertIndex =
                pageStartIndex + bannerSkipRows * skipMultiplier - offset;
            } else if (bannerPosition === 1) {
              pageInsertIndex =
                pageStartIndex +
                bannerSkipRows * skipMultiplier -
                offset +
                (skipMultiplier - rowsOccupied);
            }

            if (!isFirstElement) {
              pageInsertIndex += firstAdjustment;
            }

            const dontFetchedPagesElements =
              this.itemsOnPage * (this.lowerFetchedPage - 1);
            if (pageInsertIndex - dontFetchedPagesElements >= 0) {
              if ((this.$route.query.page || 1) > 1) {
                pageInsertIndex = pageInsertIndex - dontFetchedPagesElements; // remove extra indexes from not rendered pages
              }
              bannerParams.push({
                insertIndex: pageInsertIndex,
                ...banner,
              });

              offset += rowsOccupied * columnsOccupied;
              totalOffset += rowsOccupied * columnsOccupied;
              totalBannerCount++;
              isFirstElement = false;
              isNewPage = false;
            }
          });
      });

      this.bannerParams = bannerParams;
    },
    setProductCardPage(items, page) {
      return items.map((el) => ({
        ...el,
        item_page: page,
      }));
    },
    setLowerFetchedPage(page) {
      this.lowerFetchedPage = Math.min(
        Number(page),
        Number(this.lowerFetchedPage)
      );
    },
  },
};
