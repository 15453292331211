
import useMetaIcons from "~/mixins/useMetaIcons";
import MarketingTopBanner from "~/components/common/MarketingTopBanner.vue";

export default {
  components: {
    MarketingTopBanner,
    SystemPopup: () => import("~/components/popups/SystemPopup"),
    AppFooter: () => import("~/components/common/AppFooter"),
    Header: () => import("@/components/common/Header.vue"),
    // SideMenu: () => import("~/components/common/SideMenu.vue"),
    MarketingPopup: () => import("~/components/popups/MarketingPopup.vue"),
  },
  mixins: [useMetaIcons],
};
