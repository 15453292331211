export default {
  homePage: "Domovská stránka",
  page: "Stránka",
  close: "Zavrieť",
  bestSelling: "Najpredávanejší",
  seeAllProduct: "Zobraziť všetky produkty",
  quickView: "Rýchle zobrazenie",
  addToWishlist: "Pridať do zoznamu želaní",
  addToCompare: "Pridať na porovnanie",
  compare: "Porovnajte",
  addToCart: "Pridať do košíka",
  searchTitle: "Vyhľadávanie",
  searchButton: "Vyhľadávanie",
  searchPlaceholder: "Hľadajte tu..",
  checkout: "Pokladňa",
  viewCart: "Zobraziť nákupný košík",
  subtotal: "Medzisúčet",
  cartQuantity: "Košík ({count})",
  filter: "Filter",
  sortby: "Triediť podľa",
  showFilters: "Zobraziť filtre",
  collection: "Kolekcia",
  category: "Kategória",
  materials: "Materiály",
  stones: "Kamene",
  viewResult: "Zobraziť výsledok",
  relatedProducts: "Súvisiace produkty",
  nextBtn: "Ďalšie",
  prevBtn: "Predch.",
  byAuthor: "Autor: {author}",
  articles: "Články",
  allArticles: "Všetky články",
  recentlyViewed: "Naposledy zobrazené",
  backTohomepage: "Späť na domovskú stránku",
  text404: "Táto stránka bola pravdepodobne niekam presunutá.",
  latestArticles: "Najnovšie články",
  all: "všetky",
  searchInBlog: "Hľadajte v blogu",
  shopCategories: "Kategórií produktov",
  shopNow: "Nakupujte teraz",
  status: "Stav",
  buyNow: "Kúpte teraz",
  back: "Späť",
  forgotPassword: "zabudli ste heslo?",
  emailAddressPlaceholder: "Emailová adresa",
  passwordPlaceholder: "Heslo",
  firstNamePlaceholder: "Meno",
  lastNamePlaceholder: "Priezvisko",
  submit: "Potvrdiť",
  signIn: "Prihlásiť sa",
  signOut: "Odhlásiť Sa",
  createAccount: "Vytvoriť účet",
  forgotTitle: "Obnoviť heslo",
  forgotDesc:
    "Zadajte emailovú adresu priradenú k vášmu účtu. Kliknite na odoslať, aby vám bolo heslo zaslané emailom.",
  loginTitle: "Existujúci zákazníci",
  loginDesc: "Prihláste sa do svojho účtu nižšie:",
  registerTitle: "Noví zákazníci",
  registerDesc: "Vytvorte si účet nižšie:",
  profile: "Profil",
  myOrders: "Moje objednávky",
  wishlist: "Zoznam želaní",
  addedToWishlist: "Pridané do zoznamu želaní",
  compared: "Porovnané",
  product: "Produkt",
  price: "Cena",
  qty: "Množ",
  edit: "Upraviť",
  cartEmpty: "Prázdny košík",
  discountCode: "Zľavový kód",
  discountCodePlaceholder: "Zadajte zľavový kupón",
  applyCoupon: "Použiť kupón",
  orderSummary: "Zhrnutie objednávky",
  total: "Celkom",
  proceedToCheckout: "Pokračujte k pokladni",
  acceptPaymentMethods: "Akceptujeme spôsoby platby",
  continueShopping: "Pokračovať v nákupe",
  shoppingCart: "Nákupný košík",
  companyPlaceholder: "Meno spoločnosti",
  streetAddressPlaceholder: "Adresa",
  cityPlaceholder: "Mesto",
  zipCodePlaceholder: "PSČ",
  phoneNumberPlaceholder: "Telefónne číslo",
  shipToDifferentAddress: "Doručiť na inú adresu?",
  orderNotePlaceholder: "Poznámka k objednávke (voliteľné)",
  privacyText:
    "Vaše osobné údaje sa použijú na spracovanie vašej objednávky, podporu vášho zážitku na tejto webovej stránke a na iné účely opísané v našom článku",
  privacyPolicy: "Zásady ochrany osobných údajov",
  placeOrder: "Zadať objednávku",
  yourOrder: "Vaša objednávka",
  billingDetail: "Detail faktúry",
  apply: "Aplikovať",
  logInYourAccount: "Prihláste sa do svojho účtu",
  shopByTheLook: "Nakupujte podľa looku",
  instagramBlockSubtitle: "Sledujte náš Instagram alebo nás označte",
  thanksOrder: "Ďakujeme za objednávku!",
  orderCompleteDesc:
    "Vašu objednávku pripravíme na odoslanie. O odoslaní vás budeme informovať",
  orderNumber: "Číslo objednávky",
  customerInformation: "Informácie pre zákazníka",
  shippingAddress: "Dodacia adresa",
  billingAddress: "Fakturačná adresa",
  customerDetails: "Podrobnosti o zákazníkovi",
  deliveryDetail: "Detail doručenia",
  needHelp: "Potrebujete pomoc? Kontaktuj nás",
  policyBanner:
    "Táto webová stránka používa súbory cookie, aby sme vám zabezpečili čo najlepší zážitok z našej webovej stránky.",
  gotIt: "Mám to",
  acceptFor: "Prijať za",
  subscribe: "Podpísať sa",
  bySubscribingYouAcceptThe: " Podpísanim sa súhlasíte s",
  beTheFirstToGetTheLatestNewsAboutTrendsPromotionsAndMuchMore:
    "Buďte prvý, kto získa najnovšie správy o trendoch, akciách a oveľa viac!",
  newsletter: "Newsletter",
  omara: "OMARA",
  home: "Domov",
  youHaveSuccessfullySubscribed: "Úspešne ste sa podpísali",
  noData: "Žiadna informácia",
  noImages: "Žiadne obrázky",
  success: "Úspech",
  error: "Chyba",
  confirmMessage:
    "Registrácia prebehla úspešne, postupujte podľa pokynov v e-maile, ktorý vám bol zaslaný",
  recoverPasswordMessage:
    "E-mail bol úspešne odoslaný, postupujte podľa pokynov v e-maile, ktorý vám bol zaslaný",
  emailVerifySuccess: "Váš e-mail bol úspešne overený",
  passwordRecoverySuccess: "Vaše heslo bolo úspešne obnovené",
  passwordUpdateSuccess: "Vaše heslo bolo úspešne aktualizované",
  loading: "Načítava",
  blogPageTitle: "Náš blog",
  blogPageDescription: "blogPageDescription",
  passwordRecovery: "obnova hesla",
  pleaseFillTheForm: "Vyplňte prosím formulár",
  newPassword: "Nové heslo",
  confirmNewPassword: "Potvrďte nové heslo",
  recover: "obnoviť",
  catalog: "Katalóg",
  showMore: "Zobraziť viac",
  showLess: "Zobraziť menej",
  shipping: "Doprava",
  shippingDetail: "Podrobnosti dopravy",
  free: "Zadarmo",
  directBankTransfers: "Priame bankové prevody",
  giftCard: "Darčeková karta",
  giftCardAmount: "Suma darčekovej karty",
  discount: "Zľava",
  view: "Zobraziť",
  expressCheckout: "Expresná pokladňa",
  or: "alebo",
  allTransactionsAreSecureAndEncrypted:
    "Všetky transakcie sú bezpečné a šifrované.",
  yourOrderIsFree: "Vaša objednávka je bezplatná, nevyžaduje sa žiadna platba.",
  payment: "Platba",
  change: "zmeniť",
  shipTo: "poslať do",
  contact: "kontakt",
  completeOrder: "dokončiť objednávku",
  estimateDeliveryDate: "Odhadovaný dátum doručenia",
  yourMessageIsSent: "Vaša správa je odoslaná",
  weWillGetBackToYouWithAnAnswerAsSoonAsPossible:
    "Čo najskôr sa vám ozveme s odpoveďou.",
  email: "E-mail",
  birthday: "Narodeniny",
  save: "Uložiť",
  changeUserInfoText: "Informácie o používateľovi boli aktualizované",
  updatePassword: "Obnoviť heslo",
  currentPassword: "Aktuálne heslo",
  changeUserPasswordText: "Používateľské heslo bolo aktualizované",
  yourEmailPreferences: "Vaše preferencie e-mailu",
  selectWhichEmailsYouWouldLikeToReceive:
    "Vyberte, ktoré e-maily chcete dostávať:",
  changePassword: "Zmeniť heslo",
  updateEmailPreferences: "Aktualizujte preferencie e-mailu",
  dateOrdered: "objednaný dátum",
  orderNo: "číslo objednávky",
  returns: "Návraty",
  orderTotal: "Celková suma objednávky",
  orderDetails: "Podrobnosti objednávky",
  paymentSummary: "Súhrn platieb",
  discountCard: "Zľavová karta",
  tax: "Poplatok",
  productDiscount: "Zľava na produkt",
  paymentMethod: "spôsob platby",
  deliveryAddress: "adresa dodania",
  shippingAddresses: "Dodacie adresy",
  addNewAddress: "pridať novú adresu",
  create: "Vytvoriť",
  editAddress: "Upraviť adresu",
  addressDeleted: "Adresa bola odstránená",
  deleteAddress: "Odstrániť adresu",
  addressAdded: "Adresa bola pridaná",
  addressEdited: "Adresa bola upravená",
  orderTracking: "Sledovanie objednávky",
  estimatedDelivery: "Očakávaný dátum doručenia",
  completed: "Dokončený",
  inProgress: "Prebieha",
  next: "Ďalší",
  textMeStatusUpdates: "AKTUALIZÁCIE STAVU MI NAPIŠTE SPRÁVOU",
  collections: "Kolekcie",
  description: "Popis",
  shipReturn: "odoslanie & vrátenie",
  askAQuestion: "Opýtať sa otázku",
  fromPrice: "Od {price}",
  selectAddress: "Vyberte adresu",
  enterPromoCode: "Zadajte zľavový kód",
  resetAll: "Zmazať všetko",
  remove: "Odstrániť",
  country: "Krajina",
  maintenancePage: "Režim údržby",
  stripePaymentError: "Chyba platby Stripe",
  stripeInitError: "Stripe init error",
  selectShippingMethod: "Vyberte spôsob dopravy",
  filterTitles: {
    collections: "Kolekcia",
    categories: "Kategória",
    materials: "Materiály",
    stones: "Kamene",
    headstyles: "Head styles",
    stoneshapes: "Stone shapes",
  },
  configurator: {
    item: "Položka",
    startingAt: "Už od",
    total: "Celkom",
    addToBag: "Pridať do košíka",
    remove: "Odstrániť",
    addItem: "Pridať položku",
    characters: "Znaky",
    yourDesign: "Váš dizajn",
    openMap: "Vyberte si na mape",
    latitude: "Zemepisná šírka",
    longitude: "Zemepisná dĺžka",
    clear: "Vymazať",
    clearAll: "Vymazať všetko",
    addedToWishlist: "Pridané do zoznamu želaní",
    addToWishlist: "Pridať do zoznamu želaní",
    compare: "Porovnať",
    addedToCompareList: "Pridané do  porovnania",
    size_guide: "Zistite Svoju Veľkosť",
    outOfStock: "Vypredané",
    productDetails: "Detaily produktu",
  },
  troubleCreateOrder:
    "Ľutujeme, momentálne sa vyskytli problémy s vytvorením objednávky",
  estimatedDeliveryDate: "Predpokladaný čas doručenia",
  orderNotes: "Poznámky k objednávke",
  deliveryMethod: "Spôsob doručenia",
  estimated: "Predpokladaný",
  days: "dni",
  brand: "Značka",
  sku: "Sku",
  productDetails: "Detaily produktu",
  acceptAll: "Prijať Všetky Súbory Cookie",
  selectACountry: "Vyberte Svoju Krajinu",
  continue: "Pokračovať",
  shippingMethod: "Spôsob dopravy",
  finalizeAndPay: "Dokončiť a Zaplatiť",
  createOrderErrorText:
    "Chyba pri vytváraní objednávky. Kontaktujte prosím zákaznícku podporu.",
  createSessionErrorText: "Niečo sa pokazilo. Prosím skúste znova.",
  cart: "Košik",
  review: "Kontrola",
  paymentStatus: "Stav platby",
  unpaid: "Nezaplatené",
  paid: "Zaplatené",
  refundStarted: "Začala sa refundácia",
  refunded: "Refundované",
  partiallyRefunded: "Čiastočne refundované",
  voided: "Zrušené",
  fillTheFormBelow: "Vyplňte formulár nižšie",
  newClients: "Noví zákazníci",
  createMyAccount: "Vytvoriť môj účet",
  createAnAccountByClickingBelow:
    "Vytvorte si účet kliknutím nižšie a získajte",
  percentOff: "{percent}% zľavu",
  yourFirstOrder: "na prvú objednávku",
  yourCartIsEmpty: "Vaš košík je prázdny",
  login: "Existujúci zákazníci",
  sorting: "Triedenie",
  priceFromLowestToHighest: "Cena od najnižšej po najvyššiu",
  priceFromHighestToLowest: "Cena od najvyššej po najnižšiu",
  defaultV: "Štandardný",
  use: "Použiť",
  notificationLanguage: "Jazyk oznámení",
  onlinePayment: "Online platba",
  bankTransfer: "Bankový prevod",
  paymentMethodC: "Spôsob platby",
  bankTransferDisclaimer:
    "Dostanete e-mail so všetkými potrebnými údajmi o platbe",
  selectJewelry: "Vyberte šperky",
  nextStep: "Ďalši krok",
  gold: "Zlato",
  platinum: "950 Platina",
  silver: "925 Striebro",
  goldOverSilver: "Pozlátené",
  recommended: "Odporúčané",
  thePerfectComplement: "Dokonalé doplnenie",
  closePopup: "Zavrieť vyskakovacie okno",
  closeFilter: "Zatvorte filter",
  removeFromComparison: "Odstrániť z porovnania",
  removeFromWishlist: "Odstrániť zo zoznamu želaní",
  closeCartDrawer: "Zatvorte zásuvku košíka",
  removeDiscount: "Odstrániť zľavu",
  openFilePreview: "Otvoriť ukážku súboru",
  closeSearchPopup: "Zatvorte kontextové okno vyhľadávania",
  closeModal: "Zatvoriť modálny režim",
  removeCartItem: "Odstráňte položku košíka",
  ariaGoToSlide: "Choď na snímku {index}",
  ariaPrevSlide: "Predchádzajúca snímka",
  ariaNextSlide: "Nasledujúca snímka",
  viewThePhotoOnInstagram: "Zobraziť fotografiu na Instagrame",
  ariaViewProduct: `Zobraziť produkt {title}`,
  ariaBackTo: `Späť k {title}`,
  thankYouForSubscription: "Ďakujeme za prihlásenie",
  thankYouForSubscribingExploreOurExclusiveJewelryCollectionsAndStayTunedForUpdates:
    "Sme radi, že ste sa pridali k nám. Objavte naše exkluzívne kolekcie šperkov a nezmeškajte novinky a špeciálne ponuky.",
  startShoppingNow: "Začnite nakupovať teraz",
  didntReceiveYourEmail: "Nedostali ste e-mail?",
  checkYourSpamFolderOrContactOurSupport:
    "Skontrolujte zložku spam alebo <a href='mailto:info@omara.sk'>nás kontaktujte</a>.",
  dontHaveAnAccountSignUp: "Nemáte účet? Zaregistrujte sa",
  alreadyHaveAnAccountLogIn: "Už máte účet? Prihláste sa",
  bannerImage: "Obrázok bannera",
  outOfStock: "Vypredané",
  requestPrice: "Požiadať o cenu",
  continueWith: "Pokračovať s {title}",
  orSignIn: "Alebo sa prihlásiť",
  orSignUp: "Alebo sa zaregistrovať",
  yourAccountHasBeenSuccessfullyCreated: "Váš účet bol úspešne vytvorený",
  discountCodeAndGiftCardsNotice: "<span class='tw-font-semibold'>Zľavové kódy:</span> Na jednu objednávku môžete použiť len jeden kód.<br><div class='pt-1'><span class='tw-font-semibold'>Darčekové karty:</span> Zľavové kódy je možné použiť s viacerými darčekovými kartami na jednu objednávku.</div>",
  watch: "Sledovať",
};
