export default {
  bind(el, binding) {
    // Create a resize observer and attach it to the element
    // const resizeObserver = new ResizeObserver((entries) => {
    //   for (const entry of entries) {
    //     // Call the provided callback function on resize
    //     if (binding.value && typeof binding.value === "function") {
    // binding.value(entry);
    //     }
    //   }
    // });

    // Create a mutation observer and attach it to the element
    const mutationObserver = new MutationObserver(() => {
      if (binding.value && typeof binding.value === "function") {
        binding.value({ contentRect: el.getBoundingClientRect() });
      }
    });
    binding.value({ contentRect: el.getBoundingClientRect() }); // run once on bind

    // el._resizeObserver = resizeObserver;
    el._mutationObserver = mutationObserver;

    // resizeObserver.observe(el);
    mutationObserver.observe(el, {
      attributes: true,
      attributeFilter: ["style"],
    });
  },
  unbind(el) {
    // Clean up the observers when the directive is unbound
    // if (el._resizeObserver) {
    //   el._resizeObserver.unobserve(el);
    //   el._resizeObserver.disconnect();
    //   delete el._resizeObserver;
    // }
    if (el._mutationObserver) {
      el._mutationObserver.disconnect();
      delete el._mutationObserver;
    }
  },
};
